:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.info-modal-container {
  z-index: 9999999999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.76);
  display: flex;
  justify-content: center;
  align-items: center;
}
.info-modal-container .info-modal-box {
  background-color: red;
  position: relative;
  width: 50%;
  height: 30%;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-image: url("../../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  animation: unfoldInfoIn 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@media (max-width: 768px) {
  .info-modal-container .info-modal-box {
    animation: unfoldInfoInMobil 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
  }
}
.info-modal-container .info-modal-box .modal-close {
  position: absolute;
  right: -8px;
  top: -8px;
  padding: 0.2rem 0.5rem;
  background-color: white;
  border-radius: 50%;
  color: red;
  font-weight: bold;
}
.info-modal-container .info-modal-box .modal-close:hover {
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.911);
}
.info-modal-container .info-modal-box .info-main {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}
.info-modal-container .info-modal-box .info-main .title {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  padding: 1rem;
  font-weight: bold;
  box-shadow: 0 5px 10px black;
  border-radius: 10px;
  font-size: 1.2rem;
}
.info-modal-container .info-modal-box .info-main .title span {
  font-size: 1.6rem;
}
.info-modal-container .info-modal-box .info-main .info {
  flex: 1;
  padding: 1rem;
  box-shadow: inset 0 0 10px black;
  border-radius: 10px;
  font-size: 1.5rem;
}

@keyframes unfoldInfoIn {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 50%;
    height: 30%;
  }
}
@keyframes unfoldInfoInMobil {
  0% {
    width: 0px;
    height: 0px;
  }
  100% {
    width: 80%;
    height: -moz-max-content;
    height: max-content;
  }
}
@keyframes unfoldInfoOut {
  0% {
    width: 50%;
    height: 30%;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}
@keyframes unfoldInfoOutMobil {
  0% {
    width: 80%;
    height: -moz-max-content;
    height: max-content;
  }
  100% {
    width: 0px;
    height: 0px;
  }
}/*# sourceMappingURL=infoModal.css.map */