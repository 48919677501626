.patterncard-container {
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: scale 0.3s ease-in-out;
}
.patterncard-container:hover {
  box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
  scale: 1.02;
  cursor: pointer;
}
.patterncard-container .image-box {
  height: 400px;
}
.patterncard-container .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: top;
     object-position: top;
}
.patterncard-container .patterncard-text {
  background-color: whitesmoke;
  color: black;
  font-weight: bold;
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}/*# sourceMappingURL=patternCard.css.map */