@import "../../styles/variables";

.about-container {
    width: 100%;

    animation: openPage .5s ease-in-out forwards;

    @keyframes openPage {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .about-header {
        width: 100%;


        .header-back-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: auto;
            overflow: hidden;

            @media (max-width:768px) {
                height: 60vh;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .header-front-container {
            width: 100%;
            height: max-content;
            position: relative;
            margin-top: 100px;
            margin-bottom: 3rem;
            /* Başlık kutusunun üstündeki boşluk */
            padding: 0 3rem;
            flex-grow: 1;
            /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */

            display: flex;
            flex-direction: column;
            gap: 2rem;

            @media (max-width:768px) {
                padding: 0 1rem;
            }

            .header-front-img {
                overflow: hidden;
                width: 100%;
                height: 70vh;
                box-shadow: var(--box-shadow);
                border-radius: var(--border-radius);
                margin-bottom: 1rem;

                @media (max-width:1028px) {
                    height: 30vh;
                }

                @media (max-height:600px) {
                    height: 60vh;
                }

                img,
                video {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    // object-position: top;
                }
            }

            .about-section {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .history-image {
                    width: calc(100% + 6rem);
                    margin-left: -3rem;
                    height: auto;
                    box-shadow: var(--box-shadow);
                    border-radius: var(--border-radius);

                    overflow: hidden;

                    @media (max-width:768px) {
                        width: calc(100% + 2rem);
                        margin-left: -1rem;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 2rem;

                    @include text-font-size;

                    // @media (max-width:768px) {
                    //     padding: 1rem 0;
                    // }

                    .title {
                        margin-bottom: 1rem;
                    }
                }

                .quality-policy {
                    width: 100%;
                    height: max-content;
                    padding: 3rem 5rem;

                    background-image: url("../../assets//images/aboutImg/quality_policy_back_img.webp");
                    background-repeat: no-repeat;
                    background-size: cover;
                    background-position: 0;

                    color: black;

                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    
                    @media (max-width:768px) {
                        padding: 3rem 1rem;
                    }

                    .paye-logo {
                        width: 300px;
                        margin-bottom: 2rem;

                        @media (max-width:768px) {
                            width: 200px;
                        }
                    }

                    h2 {
                        font-size: 2.5rem;
                        margin-bottom: 1rem;


                        @media (max-width:768px) {
                            font-size: 1.5rem;
                        }
                    }

                    p {
                        width: 100%;
                        text-align: justify;
                        padding: 1rem;
                        @include text-font-size;
                    }

                    .manager-name {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: .5rem;

                        margin-top: 2rem;

                        p {
                            width: 100%;
                            text-align: center;
                            padding: 0;
                        }
                    }

                }
            }
        }

    }

}