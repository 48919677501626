:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.homepage-container {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.homepage-container header {
  width: 100%;
  height: 100vh;
  position: relative;
}
@media (max-width: 768px) {
  .homepage-container header {
    height: 60vh;
  }
}
.homepage-container header .image-box {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
@media (max-width: 768px) {
  .homepage-container header .image-box {
    height: 60vh;
  }
}
.homepage-container header .image-box img,
.homepage-container header .image-box video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.homepage-container header .header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 3.5rem;
  color: red;
  background: linear-gradient(to right, #eb0e0e, #da8686);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@media (max-width: 768px) {
  .homepage-container header .header-text {
    font-size: 2rem;
    width: 80%;
  }
}
.homepage-container header .watch-movie-btn {
  position: absolute;
  bottom: 2rem;
  left: 3rem;
  background-color: grey;
  color: white;
  padding: 0.5rem 2rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  border: none;
  outline: none;
  font-size: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
@media (max-width: 768px) {
  .homepage-container header .watch-movie-btn {
    bottom: 1rem;
    left: 1rem;
    font-size: 0.9rem;
    padding: 0.5rem 1rem;
  }
}
.homepage-container header .watch-movie-btn img {
  width: 1.2rem;
}
@media (max-width: 768px) {
  .homepage-container header .watch-movie-btn img {
    width: 0.9rem;
  }
}
.homepage-container header .watch-movie-btn:hover {
  cursor: pointer;
  background-color: rgb(139, 139, 139);
}
.homepage-container header .social-media-links {
  position: absolute;
  bottom: 2rem;
  right: 3rem;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
@media (max-width: 768px) {
  .homepage-container header .social-media-links {
    bottom: 1rem;
    right: 1rem;
  }
}
.homepage-container header .social-media-links .link-icon-box {
  opacity: 0.8;
  filter: drop-shadow(var(--box-shadow));
}
@media (max-width: 768px) {
  .homepage-container header .social-media-links .link-icon-box img {
    width: 35px;
  }
}
.homepage-container header .social-media-links .link-icon-box:hover {
  cursor: pointer;
  opacity: 1;
}
.homepage-container .who-are-we {
  padding: 2rem 3rem;
}
@media (max-width: 768px) {
  .homepage-container .who-are-we {
    padding: 1rem;
  }
}
.homepage-container .who-are-we .text {
  margin-bottom: 2rem;
  font-size: var(--text-font-size);
}
@media (max-width: 768px) {
  .homepage-container .who-are-we .text {
    font-size: var(--small-text-font-size);
  }
}
.homepage-container .who-are-we .text .title {
  width: -moz-max-content;
  width: max-content;
  padding: 0.5rem 3rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 2rem;
}
.homepage-container .who-are-we .images-content {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}
.homepage-container .who-are-we .images-content .image-box {
  width: calc(50% - 1rem);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: scale 0.3s ease-in-out;
}
.homepage-container .who-are-we .images-content .image-box:hover {
  box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
  scale: 1.02;
}
@media (max-width: 768px) {
  .homepage-container .who-are-we .images-content .image-box {
    width: 100%;
  }
}
.homepage-container .who-are-we .images-content .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.homepage-container .what-do-we-produce {
  padding: 1rem 3rem;
}
@media (max-width: 768px) {
  .homepage-container .what-do-we-produce {
    padding: 1rem;
  }
}
.homepage-container .what-do-we-produce .text {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
  font-size: var(--text-font-size);
}
@media (max-width: 768px) {
  .homepage-container .what-do-we-produce .text {
    font-size: var(--small-text-font-size);
  }
}
.homepage-container .what-do-we-produce .text .title {
  width: -moz-max-content;
  width: max-content;
  padding: 0.5rem 1rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.homepage-container .what-do-we-produce .text ul {
  list-style-type: none;
}
.homepage-container .what-do-we-produce .images-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
}
.homepage-container .what-do-we-produce .images-container .image-box {
  width: calc(33.33% - 2rem);
  height: auto;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: scale 0.3s ease-in-out;
}
.homepage-container .what-do-we-produce .images-container .image-box:hover {
  box-shadow: 0 0 10px rgb(43, 43, 43);
  scale: 1.02;
}
@media (max-width: 768px) {
  .homepage-container .what-do-we-produce .images-container .image-box {
    width: calc(50% - 2rem);
  }
}
@media (max-width: 500px) {
  .homepage-container .what-do-we-produce .images-container .image-box {
    width: 100%;
    height: 30vh;
  }
}
.homepage-container .what-do-we-produce .images-container .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=homePage.css.map */