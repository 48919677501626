// galery.scss

.galery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
}

.galery-item {
    width: 100%;
    min-height: 250px;
    max-height: 500px;

    overflow: hidden;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    transition: scale .3s ease-in-out;

    position: relative;


    &:hover {
        cursor: pointer;
        scale: 1.01;
        box-shadow: 0 0 15px rgb(71, 71, 71);
    }

    &:hover .play-border {
        opacity: 1;

    }

    .ytp{
        pointer-events: none;
    }
    .play-border {
        z-index: 2;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        border: 8px solid rgb(255, 255, 255);
        box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5),
            0 0 15px rgba(0, 0, 0, 0.5);
        border-radius: 50%;
        padding: .5rem .7rem;
        padding-bottom: 0;
        padding-top: .8rem;
        padding-right: .6rem;
        padding-left: 1rem;
        opacity: .7;

        img {
            width: 50px;
            height: 50px;
        }

    }

    .minutes-box {
        position: absolute;
        left: 20px;
        bottom: 20px;
        padding: .3rem .5rem;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);

        background-color: white;
        color: black;
        font-weight: bolder;
    }
}

.galery-image,
.galery-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.galery-video {
    max-height: 500px;
    min-height: 400px;

    @media (max-width: 767px) {
        min-height: 200px;
    }
}

/* Alt alta yerleşim için responsive düzenleme */
@media screen and (max-width: 767px) {
    .galery {
        grid-template-columns: 1fr;
    }
}