:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;

  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;

  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;

  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;

  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

@mixin title-font-size {
  font-size: var(--title-font-size);

  @media (max-width:768px) {
    font-size: var(--small-title-font-size);

  }
}

@mixin text-font-size {
  font-size: var(--text-font-size);

  @media (max-width:768px) {
    font-size: var(--small-text-font-size);

  }
}

@mixin footer-title-font-size {
  font-size: var(--footer-text-font-size);

  @media (max-width:768px) {
    font-size: var(--small-footer-title-font-size);

  }
}

@mixin footer-text-font-size {
  font-size: var(--footer-text-font-size);

  @media (max-width:768px) {
    font-size: var(--small-footer-text-font-size);

  }
}

@mixin footer-icon-font-size {
  width: var(--footer-text-font-size);

  @media (max-width:768px) {
    width: var(--small-footer-text-font-size);

  }
}

@mixin input-text-font-size {
  font-size: var(--footer-text-font-size);

  @media (max-width:768px) {
    font-size: var(--small-footer-text-font-size);

  }
}


@mixin wave-background($image) {
  background-image: url($image);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}