@import "../../styles/variables";

.footer-container {
    width: 100%;
    height: max-content;

    position: relative;
    border-radius: .5rem .5rem 0 0;
    box-shadow: var(--box-shadow);
    overflow: hidden;

    .footer-content {
        width: 100%;
        height: max-content;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2rem;
        padding: 2rem 3rem;
        padding-bottom: calc(2rem + 40px);
        z-index: 1;

        @media (max-width:768px) {
            padding: 1rem;
            padding-bottom: calc(2rem + 40px);

        }


        @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");

        ul {
            height: 100%;

            display: flex;
            flex-direction: column;
            gap: 1rem;

            list-style-type: none;
        }

        .address-box {
            display: flex;
            flex-direction: column;
            width: max-content;
            min-width: 250px;

            .title {
                @include footer-title-font-size;
                margin-bottom: 1rem;
            }

            ul {
                @include footer-text-font-size;
                height: 100%;

                img {
                    width: .8rem;
                }

                li {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;

                    a {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: .5rem;
                        text-decoration: none;
                        color: white;

                        &:hover {
                            cursor: pointer;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .links-box {
            min-width: max-content;
            height: max-content;
            display: flex;
            flex-direction: row;
            gap: 1rem;

            @media (max-width:400px) {
                width: 100%;
                flex-direction: column;
                text-align: left;
            }

            ul {
                width: max-content;

                li {
                    @include footer-text-font-size;

                    &:hover {
                        cursor: pointer;
                        font-weight: bold;
                    }

                }
            }

            .site-map {

                .title {
                    width: 100%;
                    text-align: center;
                    font-weight: bold;
                    font-size: 1rem;
                    margin-bottom: 1rem;

                    @media (max-width:768px) {
                        text-align: left;

                    }

                }

                .site-map-content {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 1rem;

                    a {
                        text-decoration: none;
                        color: white;
                    }

                    .left {
                        text-align: right;

                        @media (max-width:768px) {
                            text-align: left;

                        }
                    }

                    .right {
                        text-align: left;
                    }
                }

            }

            .contracts {
                .title {
                    width: 100%;
                    text-align: center;
                    margin-bottom: 1rem;
                    font-weight: bold;
                    @include footer-text-font-size;

                    @media (max-width:768px) {
                        text-align: left;

                    }
                }

                ul {
                    text-align: center;

                    @media (max-width:768px) {
                        text-align: left;

                    }

                }
            }

            .social-media {
                .title {
                    width: 100%;
                    text-align: right;
                    margin-bottom: 1rem;
                    font-weight: bold;
                    @include footer-text-font-size;

                    @media (max-width:768px) {
                        text-align: left;

                    }
                }

                ul {
                    text-align: right;
                    @include footer-text-font-size;

                    @media (max-width:768px) {
                        text-align: left;

                    }

                    li {
                        img {
                            @include footer-icon-font-size;
                            opacity: .9;
                        }

                        a {
                            width: 100%;
                            height: 100%;

                            display: flex;
                            flex-direction: row;
                            justify-content: flex-end;
                            align-items: center;
                            gap: .3rem;

                            text-decoration: none;
                            color: white;



                            @media (max-width:768px) {
                                flex-direction: row-reverse;
                                justify-content: flex-end;

                            }

                        }

                        &:hover {
                            font-weight: bold;

                            img {
                                opacity: 1;
                            }
                        }

                    }
                }
            }
        }
    }

    .copyright-container {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 40px;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;

        padding: 1rem 3rem;

        background-color: whitesmoke;
        color: black;

        border-radius: .5rem .5rem 0 0;
        box-shadow: var(--box-shadow);


        @media (max-width:768px) {
            padding: 1rem;

        }

        a {
            text-decoration: none;
            color: black;
        }
    }
}