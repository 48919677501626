@import "../../styles/variables";

.sheetextrusion-container {
    width: 100%;

    animation: openPage .5s ease-in-out forwards;

    @keyframes openPage {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .sheetextrusion-header {
        width: 100%;


        .header-back-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 80vh;
            overflow: hidden;
            opacity: .3;

            @media (max-width:768px) {
                height: 60vh;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .header-front-container {
            width: 100%;
            position: relative;
            margin-top: 100px;
            margin-bottom: 3rem;
            /* Başlık kutusunun üstündeki boşluk */
            padding: 0 3rem;
            flex-grow: 1;
            /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */

            display: flex;
            flex-direction: column;
            gap: 2rem;

            @media (max-width:768px) {
                padding: 0 1rem;
            }

            .header-front-img {
                display: flex;
                flex-direction: row;
                gap: 2rem;
                height: 45vh;
                margin-bottom: 5rem;

                @media (max-width:768px) {
                    flex-direction: column;
                    height: 65vh;
                    margin-bottom: 1rem;

                }

                .video-box {
                    flex: 1;
                    // height: max-content;
                    min-height: 300px;
                    border-radius: var(--border-radius);
                    box-shadow: var(--box-shadow);
                    overflow: hidden;

                    @media (max-width: 768px) {
                        min-height: 200px;

                    }

                    //     video {
                    //         width: 100%;
                    //         height: 100%;
                    //         object-fit: cover;
                    //     }

                    //     .ytp{
                    //         height: 400px;
                    //     }
                }
            }

            .sheetextrusion-section {
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .text {
                    display: flex;
                    flex-direction: column;
                    gap: 2.5rem;
                    margin-bottom: 3rem;

                    @include text-font-size;


                    .title {
                        margin-bottom: 1rem;
                    }
                }

                .images {
                    display: flex;

                    flex-grow: 1;
                    /* Ürünlerin container'ının tüm boşluğu kaplaması */
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

                    grid-gap: 2rem;


                    @media (max-width: 768px) {
                        grid-template-columns: 1fr;
                    }


                }

                .big-image {
                    height: 600px;
                    border-radius: var(--border-radius);
                    box-shadow: var(--box-shadow);
                    overflow: hidden;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                        object-position: 30% 80%;
                    }

                }

            }
        }

    }

}