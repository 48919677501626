.patterncard-container {
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    transition: scale .3s ease-in-out;

    &:hover {
        box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
        scale: 1.02;
        cursor: pointer;
    }

    .image-box {
        height: 400px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: top;
        }
    }

    .patterncard-text {
        background-color: whitesmoke;
        color: black;
        font-weight: bold;

        width: 100%;
        height: 40px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

}