@charset "UTF-8";
:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.contact-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.contact-container .contact-header {
  width: 100%;
  position: relative;
}
.contact-container .contact-header .header-back-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 85vh;
  overflow: hidden;
}
.contact-container .contact-header .header-back-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.contact-container .contact-header .front-container {
  height: 83vh;
  position: relative;
  margin-top: 90px;
  /* Başlık kutusunun üstündeki boşluk */
  padding: 0 3rem;
  flex-grow: 1;
  /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}
@media (max-width: 768px) {
  .contact-container .contact-header .front-container {
    padding: 0 1rem;
    height: -moz-max-content;
    height: max-content;
  }
}
.contact-container .contact-header .front-container .contact-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.contact-container .contact-header .front-container .contact-content .map-container {
  width: 100%;
  flex: 1;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
@media (max-width: 768px) {
  .contact-container .contact-header .front-container .contact-content .map-container {
    flex: none;
    height: 250px;
  }
}
.contact-container .contact-header .front-container .contact-content .map-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.contact-container .contact-header .front-container .contact-content .map-container .map-google {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.contact-container .contact-header .front-container .contact-content .bottom-container {
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: stretch;
  gap: 2rem;
}
@media (max-width: 768px) {
  .contact-container .contact-header .front-container .contact-content .bottom-container {
    flex-direction: column-reverse;
    padding-bottom: 1rem;
  }
}
.contact-container .contact-header .front-container .contact-content .bottom-container .footer,
.contact-container .contact-header .front-container .contact-content .bottom-container .form {
  flex: 1;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
@media (max-width: 768px) {
  .contact-container .contact-header .front-container .contact-content .bottom-container .footer,
  .contact-container .contact-header .front-container .contact-content .bottom-container .form {
    flex: none;
    height: -moz-max-content;
    height: max-content;
  }
}/*# sourceMappingURL=contact.css.map */