:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.titlebox-container {
  width: 100%;
  padding: 0.7rem 1.5rem;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.titlebox-container .back-button {
  padding: 10px;
  margin-left: -0.7rem;
  color: white;
  font-size: var(--footer-text-font-size);
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: white;
  color: rgb(233, 38, 38);
}
@media (max-width: 768px) {
  .titlebox-container .back-button {
    font-size: var(--small-footer-text-font-size);
  }
}
.titlebox-container .back-button:hover {
  cursor: pointer;
  scale: 1.04;
  transition: scale 0.3s ease-in-out;
}
.titlebox-container .title {
  color: white;
  font-weight: bold;
  font-size: var(--title-font-size);
}
@media (max-width: 768px) {
  .titlebox-container .title {
    font-size: var(--small-title-font-size);
  }
}/*# sourceMappingURL=titleBox.css.map */