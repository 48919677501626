:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.productcard-container {
  height: 100%;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: scale 0.3s ease-in-out;
}
.productcard-container:hover {
  box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
  scale: 1.02;
}
.productcard-container .image-box {
  flex: 1;
}
.productcard-container .image-box img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.productcard-container .text {
  width: 100%;
  height: 50px;
  background-color: white;
  color: black;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}/*# sourceMappingURL=productCard.css.map */