@import "../../styles/variables";

.navbar-container {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: transparent;
    border-radius: 0 0 5px 5px;

    transition: background-color 0.3s ease-in-out;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    padding: 1rem 3rem;

    @media (max-width:768px) {
        padding: 1rem;
    }

    .company-logo {
        img {
            width: 200px;

            &:hover {
                cursor: pointer;
            }
        }
    }

    .nav-links {
        @media (max-width:1128px) {
            display: none;
        }

        ul {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            list-style-type: none;

            li {
                width: max-content;
                font-size: 1.1rem;

                a {
                    text-decoration: none;
                    color: white;

                    &:hover {
                        font-weight: bold;
                    }
                }
            }

            .active-link {
                font-weight: bold;
                font-size: 1.3rem;
                transition: font-size .1s ease-in-out;
            }
        }
    }

    .nav-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
    }

    .lang-box {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .5rem;

        font-size: 1.1rem;

        span {

            &:hover {
                cursor: pointer;
                font-weight: bold;
            }
        }

        .active-lng {
            font-weight: bold;
            font-size: 1.3rem;
            transition: font-size .1s ease-in-out;
        }

    }


    .menu-toggle {
        display: none;
        padding-top: .5rem;
        cursor: pointer;

        span {
            font-size: 40px;
        }

        @media (max-width:1128px) {
            display: block;
        }
    }

    .dropdown-menu {
        display: none;
        /* ...existing styles */
        position: absolute;
        top: 100%;
        right: 0;
        bottom: 0;
        box-shadow: var(--box-shadow);
        @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");
        border-radius: 10px 0 0 10px;
        width: 50%;
        height: 88vh;
        padding: 10px;
        animation: slideIn 0.3s ease-in;

        @media (max-width:1128px) {
            display: block;
        }

        ul {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 1rem;

            list-style-type: none;

            li {

                font-size: 1.1rem;

                a {
                    text-decoration: none;
                    color: white;

                    &:hover {
                        font-weight: bold;
                    }
                }
            }

            .active-link {
                font-weight: bold;
                font-size: 1.3rem;
                transition: font-size .1s ease-in-out;
            }
        }
    }

    @keyframes slideIn {
        from {
            transform: translateX(100%);
        }

        to {
            transform: translateX(0);
        }
    }
}


.navbar-container.scrolled {

    box-shadow: var(--box-shadow);
    @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");
}