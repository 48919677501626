@charset "UTF-8";
.front-container-products {
  height: -moz-max-content;
  height: max-content;
  position: relative;
  margin-top: 100px;
  margin-bottom: 3rem;
  /* Başlık kutusunun üstündeki boşluk */
  padding: 0 3rem;
  flex-grow: 1;
  /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .front-container-products {
    padding: 0 1rem;
  }
}
.front-container-products .images-container {
  flex-grow: 1;
  /* Ürünlerin container'ının tüm boşluğu kaplaması */
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
}
@media (max-width: 768px) {
  .front-container-products .images-container {
    grid-template-columns: 1fr;
  }
}
.front-container-products .images-container a {
  text-decoration: none;
}/*# sourceMappingURL=products.css.map */