@import "../../../styles/variables";

.front-container-product-detail {
    height: max-content;
    position: relative;
    margin-top: 100px;
    margin-bottom: 3rem;
    /* Başlık kutusunun üstündeki boşluk */
    padding: 0 3rem;
    flex-grow: 1;
    /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */

    display: flex;
    flex-direction: column;
    gap: 2rem;

    animation: openPage .5s ease-in-out forwards;

    @keyframes openPage {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @media (max-width:768px) {
        padding: 0 1rem;
    }

    .detail-container {
        /* Add any styles for the container of links and images here */
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .detail-links {
            /* Add styles for the links container here */
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            .link-button {
                /* Add styles for each link button here */
                max-width: 270px;
                height: max-content;
                text-align: left;
                padding: 10px;
                color: white;
                @include input-text-font-size;
                font-weight: bold;

                border: none;
                border-radius: var(--border-radius);
                box-shadow: var(--box-shadow);

                @include wave-background("../../../assets/images/backgroundImage/red_back_image.webp");

                &:hover {
                    cursor: pointer;
                    scale: 1.04;
                    transition: scale .3s ease-in-out;
                }

                &.active {
                    /* Add styles for the active link button here */
                    border: 3px solid;
                    color: #fff;
                    scale: 1.04;
                }
            }
        }

        .detail-images {
            /* Add styles for the images container here */
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 1rem;
            

            .image-box {
                height: 300px;
                border: 4px solid rgba(243, 49, 49, 0.322);
                box-shadow: var(--box-shadow);
                border-radius: var(--border-radius);
                overflow: hidden;

                transition: scale .3s ease-in-out;

                &:hover {
                    cursor: pointer;
                    box-shadow: 0 0 10px rgb(43, 43, 43);
                    scale: 1.02;
                }


                .brand-image {
                    /* Add styles for each brand image here */
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}