@charset "UTF-8";
.referances-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.referances-container .referances-header {
  width: 100%;
  position: relative;
}
.referances-container .referances-header .header-back-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}
.referances-container .referances-header .header-back-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.referances-container .referances-header .front-container {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  margin-top: 100px;
  margin-bottom: 3rem;
  /* Başlık kutusunun üstündeki boşluk */
  padding: 0 3rem;
  flex-grow: 1;
  /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (max-width: 768px) {
  .referances-container .referances-header .front-container {
    padding: 0 1rem;
  }
}
.referances-container .referances-header .front-container .images-container {
  width: 100%;
  flex-grow: 1;
  /* Ürünlerin container'ının tüm boşluğu kaplaması */
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-gap: 2rem;
}
@media (max-width: 768px) {
  .referances-container .referances-header .front-container .images-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}/*# sourceMappingURL=referances.css.map */