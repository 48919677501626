@import "../../../styles/variables";

/* fotoğraf detay modal */
.modal-carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  // right: 0;
  // bottom: 0;
  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.568);
  z-index: 99999999999 !important;

  display: flex;
  justify-content: center;
  align-items: center;


  .images-detail-main {
    position: relative;
    width: 80%;
    height: 80vh;

    animation: unfoldIn 1.2s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;


    @media (max-width:768px) {
      width: 90%;
      height: 60vh;

    }

    .images-detail-close {
      z-index: 99;
      position: absolute;
      top: 10px;
      right: 10px;
      color: white;
      font-size: 24px;
      cursor: pointer;

      border: none;
      padding: .3rem 1rem;
      padding-bottom: .5rem;
      border-radius: 10px;

      @include wave-background("../../../assets/images/backgroundImage/red_back_image.webp");
      box-shadow: var(--box-shadow);

      @media (max-width:768px) {
        padding: .2rem .8rem;
        padding-bottom: .3rem;
      }

      &:hover {
        cursor: pointer;
        background-color: #526170;
        box-shadow: 0 0 10px rgb(250, 9, 9);
      }
    }

    .modal-image-box {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      background-color: black;
      border: 5px solid rgb(245, 78, 78);
      box-shadow: var(--box-shadow);

      video {
        width: 100%;
        height: 93%;
        object-fit: contain;
      }

      .modal-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .images-detail-button-next,
    .images-detail-button-prev {
      position: absolute;
      width: max-content;
      bottom: 10px;
      background-color: #344250;
      border: none;
      padding: .3rem 1rem;
      border-radius: 10px;

      font-size: 2rem;
      color: white;

      @include wave-background("../../../assets/images/backgroundImage/red_back_image.webp");
      box-shadow: var(--box-shadow);

      @media (max-width:768px) {
        font-size: 1rem;
        padding: .3rem .5rem;
        padding-bottom: .1rem;
      }

      &:hover {
        cursor: pointer;
        background-color: #526170;
        box-shadow: 0 0 10px rgb(158, 153, 153);

      }
    }

    .images-detail-button-next {
      right: 10px;
    }

    .images-detail-button-prev {
      left: 10px;

    }
  }
}

@keyframes unfoldIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}