@import "../../styles/variables";

.contact-form-container {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    gap: 2rem;

    padding: 1rem;
    background-color: white;


    .inputs {
        display: flex;
        flex-direction: column;
        gap: .8rem;

        input,
        textarea {
            font-family: inherit;
            color: white;
            font-weight: bold;
            padding: .3rem .5rem;
            border-radius: var(--border-radius);
            box-shadow: var(--box-shadow);
            outline: none;
            border: none;

            @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");

            &::placeholder {
                color: white;
                @include input-text-font-size;
            }


        }
    }

    .send-btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: .3rem;

        width: max-content;

        color: white;
        @include input-text-font-size;
        font-weight: bold;

        border: none;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);
        padding: .3rem .5rem;

        @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");

        &:hover {
            cursor: pointer;
            scale: 1.04;
            transition: scale .3s ease-in-out;
        }

        /* google-icon */
        .material-symbols-outlined {
            width: 20px;
            margin-right: .2rem;
            font-variation-settings:
                'FILL' 1,
                'wght' 400,
                'GRAD' 0,
                'opsz' 48,


        }

    }

}