:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.referances-card-container {
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: scale 0.1s ease-in-out;
}
.referances-card-container:hover {
  box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
  scale: 1.02;
}
.referances-card-container img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=referancesCard.css.map */