@import "../../styles/variables";

.titlebox-container {
    width: 100%;
    padding: .7rem 1.5rem;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);

    @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: .5rem;

    .back-button {
        padding: 10px;
        margin-left: -.7rem;
        color: white;
        @include input-text-font-size;
        font-weight: bold;

        border: none;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);

        background-color: white;
        color: rgb(233, 38, 38);

        &:hover {
            cursor: pointer;
            scale: 1.04;
            transition: scale .3s ease-in-out;
        }
    }

    .title {
        color: white;
        font-weight: bold;
        @include title-font-size;
    }
}