@charset "UTF-8";
:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.galery-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.galery-container .galery-header {
  width: 100%;
  position: relative;
}
.galery-container .galery-header .header-back-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100vh;
  height: auto;
  overflow: hidden;
}
.galery-container .galery-header .header-back-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.galery-container .galery-header .front-container {
  height: -moz-max-content;
  height: max-content;
  position: relative;
  margin-top: 100px;
  margin-bottom: 3rem;
  /* Başlık kutusunun üstündeki boşluk */
  padding: 0 3rem;
  flex-grow: 1;
  /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (max-width: 768px) {
  .galery-container .galery-header .front-container {
    padding: 0 1rem;
  }
}/*# sourceMappingURL=galery.css.map */