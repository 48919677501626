@charset "UTF-8";
:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.front-container-product-detail {
  height: -moz-max-content;
  height: max-content;
  position: relative;
  margin-top: 100px;
  margin-bottom: 3rem;
  /* Başlık kutusunun üstündeki boşluk */
  padding: 0 3rem;
  flex-grow: 1;
  /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */
  display: flex;
  flex-direction: column;
  gap: 2rem;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 768px) {
  .front-container-product-detail {
    padding: 0 1rem;
  }
}
.front-container-product-detail .detail-container {
  /* Add any styles for the container of links and images here */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.front-container-product-detail .detail-container .detail-links {
  /* Add styles for the links container here */
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.front-container-product-detail .detail-container .detail-links .link-button {
  /* Add styles for each link button here */
  max-width: 270px;
  height: -moz-max-content;
  height: max-content;
  text-align: left;
  padding: 10px;
  color: white;
  font-size: var(--footer-text-font-size);
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-image: url("../../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 768px) {
  .front-container-product-detail .detail-container .detail-links .link-button {
    font-size: var(--small-footer-text-font-size);
  }
}
.front-container-product-detail .detail-container .detail-links .link-button:hover {
  cursor: pointer;
  scale: 1.04;
  transition: scale 0.3s ease-in-out;
}
.front-container-product-detail .detail-container .detail-links .link-button.active {
  /* Add styles for the active link button here */
  border: 3px solid;
  color: #fff;
  scale: 1.04;
}
.front-container-product-detail .detail-container .detail-images {
  /* Add styles for the images container here */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}
.front-container-product-detail .detail-container .detail-images .image-box {
  height: 300px;
  border: 4px solid rgba(243, 49, 49, 0.322);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
  transition: scale 0.3s ease-in-out;
}
.front-container-product-detail .detail-container .detail-images .image-box:hover {
  cursor: pointer;
  box-shadow: 0 0 10px rgb(43, 43, 43);
  scale: 1.02;
}
.front-container-product-detail .detail-container .detail-images .image-box .brand-image {
  /* Add styles for each brand image here */
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}/*# sourceMappingURL=productDetail.css.map */