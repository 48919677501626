@import "../../styles/variables";

.contact-container {
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    animation: openPage .5s ease-in-out forwards;

    @keyframes openPage {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .contact-header {
        width: 100%;
        position: relative;

        .header-back-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 85vh;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .front-container {
            height: 83vh;

            position: relative;
            margin-top: 90px;
            /* Başlık kutusunun üstündeki boşluk */
            padding: 0 3rem;
            flex-grow: 1;
            /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */

            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            @media (max-width:768px) {
                padding: 0 1rem;
                height: max-content;
            }

            .contact-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 2rem;

                .map-container {
                    width: 100%;
                    flex: 1;

                    border-radius: var(--border-radius);
                    box-shadow: var(--box-shadow);
                    overflow: hidden;

                    @media (max-width:768px) {
                        flex: none;
                        height: 250px;
                    }

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .map-google {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }

                .bottom-container {
                    height: max-content;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: stretch;
                    gap: 2rem;

                    @media (max-width:768px) {
                        flex-direction: column-reverse;
                        padding-bottom: 1rem;


                    }

                    .footer,
                    .form {
                        flex: 1;
                        // height: max-content;

                        border-radius: var(--border-radius);
                        box-shadow: var(--box-shadow);

                        overflow: hidden;

                        @media (max-width:768px) {
                            flex: none;
                            height: max-content;
                        }
                    }
                }
            }

        }

    }
}