@import "../../styles/variables";

.footer-contact-container {

    width: 100%;
    height: 100%;

    overflow: hidden;
    @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");

    .address-box {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        padding: 2rem;


        ul {
            height: 100%;
            list-style-type: none;

            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 1rem;

            @include footer-text-font-size;

            img {
                @include footer-icon-font-size;
            }

            li {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .5rem;

                .title {
                    @include footer-title-font-size;
                }

                a {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: .5rem;
                    text-decoration: none;
                    color: white;

                    &:hover {
                        cursor: pointer;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}