:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.footer-contact-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.footer-contact-container .address-box {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 2rem;
}
.footer-contact-container .address-box ul {
  height: 100%;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
  font-size: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .footer-contact-container .address-box ul {
    font-size: var(--small-footer-text-font-size);
  }
}
.footer-contact-container .address-box ul img {
  width: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .footer-contact-container .address-box ul img {
    width: var(--small-footer-text-font-size);
  }
}
.footer-contact-container .address-box ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.footer-contact-container .address-box ul li .title {
  font-size: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .footer-contact-container .address-box ul li .title {
    font-size: var(--small-footer-title-font-size);
  }
}
.footer-contact-container .address-box ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: white;
}
.footer-contact-container .address-box ul li a:hover {
  cursor: pointer;
  font-weight: bold;
}/*# sourceMappingURL=footerContact.css.map */