:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.contact-form-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2rem;
  padding: 1rem;
  background-color: white;
}
.contact-form-container .inputs {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}
.contact-form-container .inputs input,
.contact-form-container .inputs textarea {
  font-family: inherit;
  color: white;
  font-weight: bold;
  padding: 0.3rem 0.5rem;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  outline: none;
  border: none;
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.contact-form-container .inputs input::-moz-placeholder, .contact-form-container .inputs textarea::-moz-placeholder {
  color: white;
  font-size: var(--footer-text-font-size);
}
.contact-form-container .inputs input::placeholder,
.contact-form-container .inputs textarea::placeholder {
  color: white;
  font-size: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .contact-form-container .inputs input::-moz-placeholder, .contact-form-container .inputs textarea::-moz-placeholder {
    font-size: var(--small-footer-text-font-size);
  }
  .contact-form-container .inputs input::placeholder,
  .contact-form-container .inputs textarea::placeholder {
    font-size: var(--small-footer-text-font-size);
  }
}
.contact-form-container .send-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.3rem;
  width: -moz-max-content;
  width: max-content;
  color: white;
  font-size: var(--footer-text-font-size);
  font-weight: bold;
  border: none;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  padding: 0.3rem 0.5rem;
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /* google-icon */
}
@media (max-width: 768px) {
  .contact-form-container .send-btn {
    font-size: var(--small-footer-text-font-size);
  }
}
.contact-form-container .send-btn:hover {
  cursor: pointer;
  scale: 1.04;
  transition: scale 0.3s ease-in-out;
}
.contact-form-container .send-btn .material-symbols-outlined {
  width: 20px;
  margin-right: 0.2rem;
  font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}/*# sourceMappingURL=contactForm.css.map */