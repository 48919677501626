.products-container {
    z-index: 1;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    animation: openPage .5s ease-in-out forwards;

    @keyframes openPage {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    .products-header {
        width: 100%;
        position: relative;

        .header-back-img {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100vh;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
}