@import "../../../styles/variables";

.info-modal-container {
    z-index: 9999999999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.76);

    display: flex;
    justify-content: center;
    align-items: center;

    .info-modal-box {
        background-color: red;
        position: relative;
        width: 50%;
        height: 30%;
        border-radius: var(--border-radius);
        box-shadow: var(--box-shadow);

        @include wave-background("../../../assets/images/backgroundImage/red_back_image.webp");

        animation: unfoldInfoIn 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        @media (max-width:768px) {
            animation: unfoldInfoInMobil 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards;

        }

        .modal-close {
            position: absolute;
            right: -8px;
            top: -8px;
            padding: .2rem .5rem;
            background-color: white;
            border-radius: 50%;
            color: red;
            font-weight: bold;

            &:hover {
                cursor: pointer;
                background-color: rgba(255, 255, 255, 0.911);
            }
        }

        .info-main {
            height: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            padding: 1rem;

            .title {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: .3rem;

                padding: 1rem;
                font-weight: bold;
                // background-color: rgba(105, 62, 13, 0.726);
                box-shadow: 0 5px 10px black;
                border-radius: 10px;

                font-size: 1.2rem;

                span {
                    font-size: 1.6rem;
                }

            }

            .info {
                flex: 1;
                padding: 1rem;
                // background-color: rgba(0, 0, 0, 0.726);
                box-shadow: inset 0 0 10px black;

                border-radius: 10px;
                font-size: 1.5rem;
            }
        }
    }
}

@keyframes unfoldInfoIn {
    0% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: 50%;
        height: 30%;
    }
}

@keyframes unfoldInfoInMobil {
    0% {
        width: 0px;
        height: 0px;
    }

    100% {
        width: 80%;
        height: max-content;
    }
}



@keyframes unfoldInfoOut {
    0% {
        width: 50%;
        height: 30%;
    }

    100% {
        width: 0px;
        height: 0px;
    }
}

@keyframes unfoldInfoOutMobil {
    0% {
        width: 80%;
        height: max-content;
    }

    100% {
        width: 0px;
        height: 0px;
    }
}