@import "../../styles/variables";

.homepage-container {
    width: 100%;
    height: max-content;
    animation: openPage .5s ease-in-out forwards;

    @keyframes openPage {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    header {
        width: 100%;
        height: 100vh;

        position: relative;

        @media (max-width:768px) {
            height: 60vh;
        }

        .image-box {
            width: 100%;
            height: 100vh;
            overflow: hidden;

            @media (max-width:768px) {
                height: 60vh;
            }

            img,
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .header-text {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            text-align: center;
            font-size: 3.5rem;
            color: red;

            background: linear-gradient(to right, #eb0e0e, #da8686);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;

            @media (max-width:768px) {
                font-size: 2rem;
                width: 80%;
            }
        }

        .watch-movie-btn {
            position: absolute;
            bottom: 2rem;
            left: 3rem;

            background-color: grey;
            color: white;
            padding: .5rem 2rem;
            box-shadow: var(--box-shadow);
            border-radius: var(--border-radius);
            border: none;
            outline: none;
            font-size: 1.2rem;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: .5rem;

            @media (max-width:768px) {
                bottom: 1rem;
                left: 1rem;
                font-size: .9rem;
                padding: .5rem 1rem;
            }

            img {
                width: 1.2rem;

                @media (max-width:768px) {
                    width: .9rem;
                }
            }


            &:hover {
                cursor: pointer;
                background-color: rgb(139, 139, 139);
            }
        }

        .social-media-links {
            position: absolute;
            bottom: 2rem;
            right: 3rem;

            display: flex;
            flex-direction: row;
            gap: 1rem;

            @media (max-width:768px) {
                bottom: 1rem;
                right: 1rem;
            }

            .link-icon-box {
                opacity: .8;
                filter: drop-shadow(var(--box-shadow));

                img {

                    @media (max-width:768px) {
                        width: 35px;
                    }
                }

                &:hover {
                    cursor: pointer;
                    opacity: 1;
                }
            }
        }
    }

    .who-are-we {
        padding: 2rem 3rem;

        @media (max-width:768px) {
            padding: 1rem;
        }

        .text {
            margin-bottom: 2rem;
            @include text-font-size;

            .title {
                width: max-content;
                padding: .5rem 3rem;
                box-shadow: var(--box-shadow);
                border-radius: var(--border-radius);

                @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");

                margin-bottom: 2rem;
            }

        }

        .images-content {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;

            .image-box {
                width: calc(50% - 1rem);
                box-shadow: var(--box-shadow);
                border-radius: var(--border-radius);
                overflow: hidden;
                transition: scale .3s ease-in-out;

                &:hover {
                    box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
                    scale: 1.02;
                }

                @media (max-width: 768px) {
                    width: 100%;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }

    }

    .what-do-we-produce {
        padding: 1rem 3rem;

        @media (max-width:768px) {
            padding: 1rem;
        }

        .text {
            display: flex;
            flex-direction: column;
            gap: 1rem;

            margin-bottom: 2rem;
            @include text-font-size;

            .title {
                width: max-content;
                padding: .5rem 1rem;
                box-shadow: var(--box-shadow);
                border-radius: var(--border-radius);

                @include wave-background("../../assets/images/backgroundImage/red_back_image.webp");
            }

            ul {
                list-style-type: none;
                // font-weight: bold;
            }

        }

        .images-container {
            display: flex;
            flex-wrap: wrap;
            gap: 2rem;
            margin-bottom: 2rem;

            .image-box {
                width: calc(33.33% - 2rem);
                height: auto;
                box-shadow: var(--box-shadow);
                border-radius: var(--border-radius);
                overflow: hidden;

                transition: scale .3s ease-in-out;

                &:hover {
                    box-shadow: 0 0 10px rgb(43, 43, 43);
                    scale: 1.02;
                }

                @media (max-width: 768px) {
                    width: calc(50% - 2rem);
                }

                @media (max-width: 500px) {
                    width: 100%;
                    height: 30vh;
                }

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
}