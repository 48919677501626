@charset "UTF-8";
:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

/* fotoğraf detay modal */
.modal-carousel-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.568);
  z-index: 99999999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal-carousel-container .images-detail-main {
  position: relative;
  width: 80%;
  height: 80vh;
  animation: unfoldIn 1.2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}
@media (max-width: 768px) {
  .modal-carousel-container .images-detail-main {
    width: 90%;
    height: 60vh;
  }
}
.modal-carousel-container .images-detail-main .images-detail-close {
  z-index: 99;
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 24px;
  cursor: pointer;
  border: none;
  padding: 0.3rem 1rem;
  padding-bottom: 0.5rem;
  border-radius: 10px;
  background-image: url("../../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: var(--box-shadow);
}
@media (max-width: 768px) {
  .modal-carousel-container .images-detail-main .images-detail-close {
    padding: 0.2rem 0.8rem;
    padding-bottom: 0.3rem;
  }
}
.modal-carousel-container .images-detail-main .images-detail-close:hover {
  cursor: pointer;
  background-color: #526170;
  box-shadow: 0 0 10px rgb(250, 9, 9);
}
.modal-carousel-container .images-detail-main .modal-image-box {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: black;
  border: 5px solid rgb(245, 78, 78);
  box-shadow: var(--box-shadow);
}
.modal-carousel-container .images-detail-main .modal-image-box video {
  width: 100%;
  height: 93%;
  -o-object-fit: contain;
     object-fit: contain;
}
.modal-carousel-container .images-detail-main .modal-image-box .modal-img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.modal-carousel-container .images-detail-main .images-detail-button-next,
.modal-carousel-container .images-detail-main .images-detail-button-prev {
  position: absolute;
  width: -moz-max-content;
  width: max-content;
  bottom: 10px;
  background-color: #344250;
  border: none;
  padding: 0.3rem 1rem;
  border-radius: 10px;
  font-size: 2rem;
  color: white;
  background-image: url("../../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  box-shadow: var(--box-shadow);
}
@media (max-width: 768px) {
  .modal-carousel-container .images-detail-main .images-detail-button-next,
  .modal-carousel-container .images-detail-main .images-detail-button-prev {
    font-size: 1rem;
    padding: 0.3rem 0.5rem;
    padding-bottom: 0.1rem;
  }
}
.modal-carousel-container .images-detail-main .images-detail-button-next:hover,
.modal-carousel-container .images-detail-main .images-detail-button-prev:hover {
  cursor: pointer;
  background-color: #526170;
  box-shadow: 0 0 10px rgb(158, 153, 153);
}
.modal-carousel-container .images-detail-main .images-detail-button-next {
  right: 10px;
}
.modal-carousel-container .images-detail-main .images-detail-button-prev {
  left: 10px;
}

@keyframes unfoldIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}/*# sourceMappingURL=modalCarousel.css.map */