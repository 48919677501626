@import "../../styles/variables";

.referances-card-container {
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;
    transition: scale .1s ease-in-out;

    &:hover {
        box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
        scale: 1.02;
    }

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}