:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.footer-container {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
.footer-container .footer-content {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 2rem;
  padding: 2rem 3rem;
  padding-bottom: calc(2rem + 40px);
  z-index: 1;
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
@media (max-width: 768px) {
  .footer-container .footer-content {
    padding: 1rem;
    padding-bottom: calc(2rem + 40px);
  }
}
.footer-container .footer-content ul {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  list-style-type: none;
}
.footer-container .footer-content .address-box {
  display: flex;
  flex-direction: column;
  width: -moz-max-content;
  width: max-content;
  min-width: 250px;
}
.footer-container .footer-content .address-box .title {
  font-size: var(--footer-text-font-size);
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .footer-container .footer-content .address-box .title {
    font-size: var(--small-footer-title-font-size);
  }
}
.footer-container .footer-content .address-box ul {
  font-size: var(--footer-text-font-size);
  height: 100%;
}
@media (max-width: 768px) {
  .footer-container .footer-content .address-box ul {
    font-size: var(--small-footer-text-font-size);
  }
}
.footer-container .footer-content .address-box ul img {
  width: 0.8rem;
}
.footer-container .footer-content .address-box ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}
.footer-container .footer-content .address-box ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
  color: white;
}
.footer-container .footer-content .address-box ul li a:hover {
  cursor: pointer;
  font-weight: bold;
}
.footer-container .footer-content .links-box {
  min-width: -moz-max-content;
  min-width: max-content;
  height: -moz-max-content;
  height: max-content;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}
@media (max-width: 400px) {
  .footer-container .footer-content .links-box {
    width: 100%;
    flex-direction: column;
    text-align: left;
  }
}
.footer-container .footer-content .links-box ul {
  width: -moz-max-content;
  width: max-content;
}
.footer-container .footer-content .links-box ul li {
  font-size: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box ul li {
    font-size: var(--small-footer-text-font-size);
  }
}
.footer-container .footer-content .links-box ul li:hover {
  cursor: pointer;
  font-weight: bold;
}
.footer-container .footer-content .links-box .site-map .title {
  width: 100%;
  text-align: center;
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .site-map .title {
    text-align: left;
  }
}
.footer-container .footer-content .links-box .site-map .site-map-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.footer-container .footer-content .links-box .site-map .site-map-content a {
  text-decoration: none;
  color: white;
}
.footer-container .footer-content .links-box .site-map .site-map-content .left {
  text-align: right;
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .site-map .site-map-content .left {
    text-align: left;
  }
}
.footer-container .footer-content .links-box .site-map .site-map-content .right {
  text-align: left;
}
.footer-container .footer-content .links-box .contracts .title {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .contracts .title {
    font-size: var(--small-footer-text-font-size);
  }
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .contracts .title {
    text-align: left;
  }
}
.footer-container .footer-content .links-box .contracts ul {
  text-align: center;
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .contracts ul {
    text-align: left;
  }
}
.footer-container .footer-content .links-box .social-media .title {
  width: 100%;
  text-align: right;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .social-media .title {
    font-size: var(--small-footer-text-font-size);
  }
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .social-media .title {
    text-align: left;
  }
}
.footer-container .footer-content .links-box .social-media ul {
  text-align: right;
  font-size: var(--footer-text-font-size);
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .social-media ul {
    font-size: var(--small-footer-text-font-size);
  }
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .social-media ul {
    text-align: left;
  }
}
.footer-container .footer-content .links-box .social-media ul li img {
  width: var(--footer-text-font-size);
  opacity: 0.9;
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .social-media ul li img {
    width: var(--small-footer-text-font-size);
  }
}
.footer-container .footer-content .links-box .social-media ul li a {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 0.3rem;
  text-decoration: none;
  color: white;
}
@media (max-width: 768px) {
  .footer-container .footer-content .links-box .social-media ul li a {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
.footer-container .footer-content .links-box .social-media ul li:hover {
  font-weight: bold;
}
.footer-container .footer-content .links-box .social-media ul li:hover img {
  opacity: 1;
}
.footer-container .copyright-container {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 1rem 3rem;
  background-color: whitesmoke;
  color: black;
  border-radius: 0.5rem 0.5rem 0 0;
  box-shadow: var(--box-shadow);
}
@media (max-width: 768px) {
  .footer-container .copyright-container {
    padding: 1rem;
  }
}
.footer-container .copyright-container a {
  text-decoration: none;
  color: black;
}/*# sourceMappingURL=footer.css.map */