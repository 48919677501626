:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.navbar-container {
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: transparent;
  border-radius: 0 0 5px 5px;
  transition: background-color 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
}
@media (max-width: 768px) {
  .navbar-container {
    padding: 1rem;
  }
}
.navbar-container .company-logo img {
  width: 200px;
}
.navbar-container .company-logo img:hover {
  cursor: pointer;
}
@media (max-width: 1128px) {
  .navbar-container .nav-links {
    display: none;
  }
}
.navbar-container .nav-links ul {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  list-style-type: none;
}
.navbar-container .nav-links ul li {
  width: -moz-max-content;
  width: max-content;
  font-size: 1.1rem;
}
.navbar-container .nav-links ul li a {
  text-decoration: none;
  color: white;
}
.navbar-container .nav-links ul li a:hover {
  font-weight: bold;
}
.navbar-container .nav-links ul .active-link {
  font-weight: bold;
  font-size: 1.3rem;
  transition: font-size 0.1s ease-in-out;
}
.navbar-container .nav-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
}
.navbar-container .lang-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.1rem;
}
.navbar-container .lang-box span:hover {
  cursor: pointer;
  font-weight: bold;
}
.navbar-container .lang-box .active-lng {
  font-weight: bold;
  font-size: 1.3rem;
  transition: font-size 0.1s ease-in-out;
}
.navbar-container .menu-toggle {
  display: none;
  padding-top: 0.5rem;
  cursor: pointer;
}
.navbar-container .menu-toggle span {
  font-size: 40px;
}
@media (max-width: 1128px) {
  .navbar-container .menu-toggle {
    display: block;
  }
}
.navbar-container .dropdown-menu {
  display: none;
  /* ...existing styles */
  position: absolute;
  top: 100%;
  right: 0;
  bottom: 0;
  box-shadow: var(--box-shadow);
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  border-radius: 10px 0 0 10px;
  width: 50%;
  height: 88vh;
  padding: 10px;
  animation: slideIn 0.3s ease-in;
}
@media (max-width: 1128px) {
  .navbar-container .dropdown-menu {
    display: block;
  }
}
.navbar-container .dropdown-menu ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  list-style-type: none;
}
.navbar-container .dropdown-menu ul li {
  font-size: 1.1rem;
}
.navbar-container .dropdown-menu ul li a {
  text-decoration: none;
  color: white;
}
.navbar-container .dropdown-menu ul li a:hover {
  font-weight: bold;
}
.navbar-container .dropdown-menu ul .active-link {
  font-weight: bold;
  font-size: 1.3rem;
  transition: font-size 0.1s ease-in-out;
}
@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.navbar-container.scrolled {
  box-shadow: var(--box-shadow);
  background-image: url("../../assets/images/backgroundImage/red_back_image.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}/*# sourceMappingURL=navbar.css.map */