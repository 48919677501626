* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    width: 100%;
    min-height: 100vh;
    font-family: 'Blinker', sans-serif;
    color: white;
    background-color: #171717;

    .layout {
        width: 100%;
    }
}