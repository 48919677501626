@charset "UTF-8";
:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.sheetextrusion-container {
  width: 100%;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.sheetextrusion-container .sheetextrusion-header {
  width: 100%;
}
.sheetextrusion-container .sheetextrusion-header .header-back-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 80vh;
  overflow: hidden;
  opacity: 0.3;
}
@media (max-width: 768px) {
  .sheetextrusion-container .sheetextrusion-header .header-back-img {
    height: 60vh;
  }
}
.sheetextrusion-container .sheetextrusion-header .header-back-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.sheetextrusion-container .sheetextrusion-header .header-front-container {
  width: 100%;
  position: relative;
  margin-top: 100px;
  margin-bottom: 3rem;
  /* Başlık kutusunun üstündeki boşluk */
  padding: 0 3rem;
  flex-grow: 1;
  /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (max-width: 768px) {
  .sheetextrusion-container .sheetextrusion-header .header-front-container {
    padding: 0 1rem;
  }
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .header-front-img {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  height: 45vh;
  margin-bottom: 5rem;
}
@media (max-width: 768px) {
  .sheetextrusion-container .sheetextrusion-header .header-front-container .header-front-img {
    flex-direction: column;
    height: 65vh;
    margin-bottom: 1rem;
  }
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .header-front-img .video-box {
  flex: 1;
  min-height: 300px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
@media (max-width: 768px) {
  .sheetextrusion-container .sheetextrusion-header .header-front-container .header-front-img .video-box {
    min-height: 200px;
  }
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section .text {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  margin-bottom: 3rem;
  font-size: var(--text-font-size);
}
@media (max-width: 768px) {
  .sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section .text {
    font-size: var(--small-text-font-size);
  }
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section .text .title {
  margin-bottom: 1rem;
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section .images {
  display: flex;
  flex-grow: 1;
  /* Ürünlerin container'ının tüm boşluğu kaplaması */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 2rem;
}
@media (max-width: 768px) {
  .sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section .images {
    grid-template-columns: 1fr;
  }
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section .big-image {
  height: 600px;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
.sheetextrusion-container .sheetextrusion-header .header-front-container .sheetextrusion-section .big-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: 30% 80%;
     object-position: 30% 80%;
}/*# sourceMappingURL=sheetExtrusion.css.map */