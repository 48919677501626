.front-container-products {
    height: max-content;
    position: relative;
    margin-top: 100px;
    margin-bottom: 3rem;
    /* Başlık kutusunun üstündeki boşluk */
    padding: 0 3rem;
    flex-grow: 1;
    /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */

    display: flex;
    flex-direction: column;
    gap: 2rem;

    animation: openPage .5s ease-in-out forwards;

    @keyframes openPage {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @media (max-width:768px) {
        padding: 0 1rem;
    }

    .images-container {
        flex-grow: 1;
        /* Ürünlerin container'ının tüm boşluğu kaplaması */
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 2rem;

        @media (max-width: 768px) {
            grid-template-columns: 1fr;
        }

        a {
            text-decoration: none;
        }
    }
}