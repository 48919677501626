@charset "UTF-8";
:root {
  --border-radius: 10px;
  --box-shadow: 0 0 10px black;
  --title-font-size: 1.3rem;
  --small-title-font-size: 1rem;
  --text-font-size: 1.2rem;
  --small-text-font-size: .9rem;
  --footer-title-font-size: 1.2rem;
  --small-footer-title-font-size: 1rem;
  --footer-text-font-size: 1rem;
  --small-footer-text-font-size: .9rem;
}

.about-container {
  width: 100%;
  animation: openPage 0.5s ease-in-out forwards;
}
@keyframes openPage {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.about-container .about-header {
  width: 100%;
}
.about-container .about-header .header-back-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
}
@media (max-width: 768px) {
  .about-container .about-header .header-back-img {
    height: 60vh;
  }
}
.about-container .about-header .header-back-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-container .about-header .header-front-container {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  position: relative;
  margin-top: 100px;
  margin-bottom: 3rem;
  /* Başlık kutusunun üstündeki boşluk */
  padding: 0 3rem;
  flex-grow: 1;
  /* Ürünlerin ve başlık kutusunun tüm boşluğu kaplaması */
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
@media (max-width: 768px) {
  .about-container .about-header .header-front-container {
    padding: 0 1rem;
  }
}
.about-container .about-header .header-front-container .header-front-img {
  overflow: hidden;
  width: 100%;
  height: 70vh;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  margin-bottom: 1rem;
}
@media (max-width: 1028px) {
  .about-container .about-header .header-front-container .header-front-img {
    height: 30vh;
  }
}
@media (max-height: 600px) {
  .about-container .about-header .header-front-container .header-front-img {
    height: 60vh;
  }
}
.about-container .about-header .header-front-container .header-front-img img,
.about-container .about-header .header-front-container .header-front-img video {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-container .about-header .header-front-container .about-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}
.about-container .about-header .header-front-container .about-section .history-image {
  width: calc(100% + 6rem);
  margin-left: -3rem;
  height: auto;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  overflow: hidden;
}
@media (max-width: 768px) {
  .about-container .about-header .header-front-container .about-section .history-image {
    width: calc(100% + 2rem);
    margin-left: -1rem;
  }
}
.about-container .about-header .header-front-container .about-section .history-image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.about-container .about-header .header-front-container .about-section .text {
  display: flex;
  flex-direction: column;
  padding-bottom: 2rem;
  font-size: var(--text-font-size);
}
@media (max-width: 768px) {
  .about-container .about-header .header-front-container .about-section .text {
    font-size: var(--small-text-font-size);
  }
}
.about-container .about-header .header-front-container .about-section .text .title {
  margin-bottom: 1rem;
}
.about-container .about-header .header-front-container .about-section .quality-policy {
  width: 100%;
  height: -moz-max-content;
  height: max-content;
  padding: 3rem 5rem;
  background-image: url("../../assets//images/aboutImg/quality_policy_back_img.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 768px) {
  .about-container .about-header .header-front-container .about-section .quality-policy {
    padding: 3rem 1rem;
  }
}
.about-container .about-header .header-front-container .about-section .quality-policy .paye-logo {
  width: 300px;
  margin-bottom: 2rem;
}
@media (max-width: 768px) {
  .about-container .about-header .header-front-container .about-section .quality-policy .paye-logo {
    width: 200px;
  }
}
.about-container .about-header .header-front-container .about-section .quality-policy h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
@media (max-width: 768px) {
  .about-container .about-header .header-front-container .about-section .quality-policy h2 {
    font-size: 1.5rem;
  }
}
.about-container .about-header .header-front-container .about-section .quality-policy p {
  width: 100%;
  text-align: justify;
  padding: 1rem;
  font-size: var(--text-font-size);
}
@media (max-width: 768px) {
  .about-container .about-header .header-front-container .about-section .quality-policy p {
    font-size: var(--small-text-font-size);
  }
}
.about-container .about-header .header-front-container .about-section .quality-policy .manager-name {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  margin-top: 2rem;
}
.about-container .about-header .header-front-container .about-section .quality-policy .manager-name p {
  width: 100%;
  text-align: center;
  padding: 0;
}/*# sourceMappingURL=about.css.map */