@import "../../styles/variables";

.productcard-container {
    height: 100%;
    box-shadow: var(--box-shadow);
    border-radius: var(--border-radius);
    overflow: hidden;

    display: flex;
    flex-direction: column;
    transition: scale .3s ease-in-out;

    &:hover {
        box-shadow: 0 0 10px rgba(130, 129, 129, 0.712);
        scale: 1.02;
    }

    .image-box {
        flex: 1;
        // height: 300px;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .text {
        width: 100%;
        height: 50px;

        background-color: white;
        color: black;
        font-weight: bold;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}